import { useState, useEffect } from 'react';
import styles from './songItem.module.less';
import SongPlayIcon from '@/assets/img/playground/song_item_play.svg';
import SongPauseIcon from '@/assets/img/playground/song_item_pause.svg';
import MusicRhythmIcon from '@/assets/img/playground/music_rhythm.svg';
import DeleteSongItemIcon from '@/assets/img/playground/delete_songItem.svg';
import ShareIcon from '@/assets/img/playground/share.svg';
import { modalConfirmWidget } from '@/components/ModalWidget/ModalWidget';
import { likeObjectAndUpdate, unlikeObjectAndUpdate, userShareObject, getLikeCnt, createShareLink } from '@/api/userLikeObject';
import { Tooltip, QRCode, message } from 'antd';
import { generateMD5 } from '@/utils/utils';

interface SongItemProps {
    song: any;
    currentSong: any;
    setCurrentSong: any;
    isPlaying: boolean;
    playMusic: any;
    pauseMusic: any;
    deleteSong: any;
    userId: number;
};

const SongItem: React.FC<SongItemProps> = ({ song, currentSong, setCurrentSong, isPlaying,
    playMusic, pauseMusic, deleteSong, userId }) => {
    const [duration, setDuration] = useState<number>(0);
    const [qrUrl, setQrUrl] = useState('null');
    const [qrLoading, setQrLoading] = useState(false);

    const handlePlay = () => {
        if (currentSong.id === song.id && isPlaying) {
            pauseMusic();
        } else {
            setCurrentSong(song);
            playMusic();
        }
    }

    // 删除歌曲，弹窗确认
    const handleDeleteSong = (songId: number) => {
        modalConfirmWidget({
            icon: <img src={DeleteSongItemIcon} style={
                {
                    width: '50px',
                    height: '50px',
                    marginRight: '20px'
                }
            } />,
            title: '删除歌曲',
            content: '确定要删除这首歌曲吗？',
            onOk: () => {
                deleteSong(songId);
            }
        });
    }

    const clickShareIcon = async () => {
        setQrLoading(true);
        const data = {
            user_id: userId,
            object_type: "music",
            object_id: song.id,
            channel: "classroom",
            extras: {},
            create_time: 0
        };
        try {
            const res = await userShareObject(data);
            if (res.status === 'success') {
                generateShareInfo();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const generateShareInfo = async () => {
        // const songData = { ...song, author_name: song.author };
        const songData = song;
        songData.author_name = song.author;
        const concatenatedString = `${songData.authorId}${songData.id}${songData.name}`;
        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            share_type: "music",
            payload: songData,
            create_time: 0
        };
        const shareLinkRes = await createShareLink(shareLinkData);
        if (shareLinkRes.status === 'success') {
            setQrUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
            setQrLoading(false);
        } else {
            setQrLoading(false);
            message.error('生成分享链接失败');
        }
    };

    useEffect(() => {
        if (song.musicUrl) {
            const audio = new Audio(song.musicUrl);
            audio.onloadedmetadata = () => {
                setDuration(audio.duration);
            };
        }
    }, [song.music_url]);
    return (
        <div
            key={song.id}
            className={styles.songItem}
            style={{ backgroundColor: '#FFFFFF' }}
            onClick={() => { setCurrentSong(song); }}
        >
            <div className={styles.songCover}>
                <img src={song.coverUrl} className={styles.coverImg} />
                <div className={styles.btnBox} onClick={handlePlay}>
                    <img src={currentSong.id === song.id && isPlaying ? SongPauseIcon : SongPlayIcon} />
                </div>
            </div>
            <div className={styles.songInfo}>
                <div className={styles.textInfo}>
                    <div className={styles.songName}>
                        {song.name}
                    </div>
                    <div className={styles.songAuthor}>
                        作者: {song.author}
                    </div>
                </div>
                <div className={styles.rightBox}>
                    <div className={styles.shareSong} onClick={clickShareIcon}>
                        <Tooltip
                            color='white'
                            trigger='click'
                            title={<QRCode
                                value={qrUrl} size={150}
                                status={qrLoading ? "loading" : "active"} />
                            }

                        >
                            <img src={ShareIcon} alt="" />
                        </Tooltip>
                    </div>
                    <div className={styles.delSong}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteSong(song.id)
                        }}>
                        <img src={DeleteSongItemIcon} />
                    </div>
                    {/* <div className={styles.likeSong}>
                        <img src={UnLikeIcon} alt="" />
                    </div> */}
                    <div className={styles.durationBox}>
                        {
                            currentSong.id === song.id && isPlaying && (
                                <div className={styles.musicRhythm}>
                                    <img src={MusicRhythmIcon} />
                                </div>
                            )
                        }
                        <div className={styles.duration}>
                            时长: {duration ?
                                `${Math.floor(duration / 60).toString().padStart(2, '0')}:${Math.floor(duration % 60).toString().padStart(2, '0')}`
                                : '00:00'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SongItem;